import React from "react";

import { Layout, SEO } from '../../../components/structure';
import { Button, Content, Hero, PremUpgradeContainer, Testimonial, PointsLoyalty, CallToAction, SeatBlocker, SpeedPass, LoyaltyCommerce } from "../../../components/blocks"
import styled, { css } from "styled-components";
import { brand, font, mq } from "../../../styles";
import GatsbyImage from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"



const IndustryCruisePage = (props) => {

  const pageQuery = useStaticQuery(graphql`
    query {
      hero: file(base: { eq: "Plusgrade-Hero-Banner-Air.jpg" }) {
       ...FullHeroBackground
      },
      rightArrowIcon: file(base: {eq: "arrow-right-blue.png"}) {
        ...ButtonIcon
      }
      rightArrowIconWhite: file(base: {eq: "arrow-right.png"}) {
        ...ButtonIcon
      }
      airIcon: file(base: {eq: "AirplaneIcon.png"}) {
        childImageSharp {
          fixed(width: 40, height: 40, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      clickUpgradeIcon: file(base: { eq: "click-upgrade.png" }) {
        childImageSharp {
            fixed(width: 45, height: 80, webpQuality: 100) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
      }
      partnerIncrease: file(base: { eq: "partnerIncrease.png" }) {
          childImageSharp {
              fixed(width: 150, height: 150, webpQuality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
              }
          }
      }
      revenueIncrease: file(base: { eq: "revenueIncrease.png" }) {
          childImageSharp {
              fixed(width: 150, height: 150, webpQuality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
              }
          }
      }
      cloudUpload: file(base: { eq: "cloudUpload.png" }) {
          childImageSharp {
              fixed(width: 70, height: 60, webpQuality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
              }
          }
      }
      creditCard: file(base: { eq: "creditCard.png" }) {
          childImageSharp {
              fixed(width: 60, height: 60, webpQuality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
              }
          }
      }
      offerDistribute: file(base: { eq: "offerDistribute.png" }) {
          childImageSharp {
              fixed(width: 70, height: 60, webpQuality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
              }
          }
      }
      revPlatformBackground: file(base: { eq: "IndustryCruiseRevenuePlatform.jpg" }) {
        childImageSharp {
          fixed(width: 1100, height: 700, webpQuality: 100){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      airTestimonials: file(base: { eq: "IndustryAirTestimonials.jpg" }) {
        childImageSharp {
          fixed(width: 1100, height: 600, webpQuality: 100){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      growthArrow: file(base: { eq: "ArrowClimber.png" }) {
        childImageSharp {
          fixed(width: 500, height: 300, webpQuality: 100){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      airCanada: file(base: { eq: "AC_Inline.png" }) {
        ...CruiseLogo
      }
      lingus: file(base: { eq: "AerLingus_Inline.png" }) {
        ...CruiseLogo
      }
      latam: file(base: { eq: "Latam_Inline.png" }) {
        ...CruiseLogo
      }
      va: file(base: { eq: "VA_Inline.png" }) {
        ...CruiseLogo
      }
      lufthansa: file(base: { eq: "Lufthansa_Inline.png" }) {
        ...CruiseLogo
      }
      singapore: file(base: { eq: "Singapore_Inline.png" }) {
        ...CruiseLogo
      }
      etihad: file(base: { eq: "EtihadGold.png" }) {
        ...CruiseLogo
      }
      qantas: file(base: { eq: "Qantas.png" }) {
        ...CruiseLogo
      }
      whiteLabel: file(base: { eq: "whiteLabelIcon.png" }) {
        childImageSharp {
            fixed(width: 110, height: 110, webpQuality: 100) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
      }
      zeroDilution: file(base: { eq: "zeroDilutionIcon.png" }) {
        childImageSharp {
            fixed(width: 140, height: 110, webpQuality: 100) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
      }
      provenGrowth: file(base: { eq: "provenGrowth.png" }) {
        childImageSharp {
            fixed(width: 120, height: 110, webpQuality: 100) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
      }
      merchantRecord: file(base: { eq: "merchantRecordIcon.png" }) {
        childImageSharp {
            fixed(width: 90, height: 110, webpQuality: 100) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
      }
      fullInt: file(base: { eq: "fullIntegrationIcon.png" }) {
        childImageSharp {
            fixed(width: 70, height: 110, webpQuality: 100) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
      }
      fastestTime: file(base: { eq: "fastestTimeIcon.png" }) {
        childImageSharp {
            fixed(width: 95, height: 110, webpQuality: 100) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
      }
      simpleUX: file(base: { eq: "simpleUX.png" }) {
        childImageSharp {
            fixed(width: 50, height: 60, webpQuality: 100) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
      }
      generateAncillary: file(base: { eq: "generateAncillary.png" }) {
        childImageSharp {
            fixed(width: 58, height: 60, webpQuality: 100) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
      }
      inventoryControl: file(base: { eq: "inventoryControl.png" }) {
        childImageSharp {
            fixed(width: 50, height: 60, webpQuality: 100) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
      }

    }
  `);

  const PlatformRevenueContainer = styled.div`
  
  ${ font.imports.sherika }
    width: 100%;
    height: auto;
    min-height: 500px;
    margin: auto;
    position: relative;

    .background-con {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      filter: grayscale(1) contrast(1.2);

      &:after {
        content: '';
        position: absolute;
        z-index: 350;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background:linear-gradient(0deg, rgba(0, 4, 20, 0.65), rgba(0, 4, 20, 0.65));
      }

      .gatsby-image-wrapper {
        width: 100% !important;
        height: 100% !important;
      }
    }

    .content-con {
      position: relative;
      z-index: 400;
      width: 95%;
      max-width: 1400px;
      margin: auto;
      height: 100%;
      padding: 20px 0;

      h3 {
        font-size: 38px;
        text-align: center;
        color: ${brand.colors.pg_blue};
        font-weight: 600;
        text-shadow: 0 0 40px ${brand.colors.pg_primary_dark};
      }

      h4 {
        font-size: 24px;
        text-align: center;
        color: #fff;
        font-weight: 600;
      }

      .icon-content-con {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        position: relative;
        top: 20px;
        margin-bottom: 0;

        @media only screen and ${mq.maxMd} {
          flex-direction: column;
          align-items: center;
          justify-content: center;
          top: 0;
          width: 95%;
          margin: auto;
          max-width: 500px;
        }
      }
    }

  `;

  const OutperformContent = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;
    background-color: ${brand.colors.pg_primary_light};

    .left-section {
      flex-basis: 50%;
      height: 450px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .right-section {
      height: 450px;
      flex-basis: 50%;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 90px;

      .blue-highlight {
        font-size: 80px;
        color: ${brand.colors.pg_blue};
        display: block;
      }

      .copy {
        display: block;
        font-size: 22px;
        color: #000414;
        width: 54%;
        max-width: 350px;
        margin: 15px 0;
      }
    }
  `;

  const PlatformPerksContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    max-width: 1050px;
    margin: auto;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    gap: 40px;
    padding: 60px 0;

    @media only screen and ${mq.maxMd} {
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      max-width: 400px;
    }



    .headline-highlight{
      display: none;
    }
  `

const CruiseParnters = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 50px auto;

    .cruise-logo-container {
      width: 95%;
      max-width: 1400px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 40px;

      @media only screen and ${mq.maxMd} {
        flex-wrap: wrap;
        justify-content: center;
      }

      .logo-con {
        flex-basis: 200px;
      }
    }
    
`

const PlatformDetailContainer = styled.div`
    width: 100%;
    background-color: ${brand.colors.pg_blue};

    .inner-icon-container {
      width: 95%;
      max-width: 1020px;
      margin: auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      padding: 40px 0;
      gap: 40px;
      
      @media only screen and ${mq.maxMd} {
        width: 80%;
      }
    }
`

const SpeedPassPerksContainer = styled.div`
  width: 100%;
  background-color: #F6F6F6;

    .inner-speedpass-icon-con {
      width: 95%;
      max-width: 1020px;
      margin: auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      padding: 40px 0;
      gap: 40px;

      @media only screen and ${mq.maxMd} {
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        max-width: 400px;
      }
    }
`
  return (
    <Layout>
      <SEO title="Transport aérien | Plusgrade" 
        description={'Offrez aux passagers des expériences incroyables tout en générant d\'importantes revenus auxiliaires sur chaque vol.'}
        keywords={'airline, flight, upgrades'}
      />
      <Content whiteBG>
        <Hero 
          title={'Transport aérien'}
          image = {pageQuery['hero']}
          icon = {pageQuery['airIcon']}
          subtitle = {"Offrez aux passagers des expériences incroyables tout en générant d'importants revenus auxiliaires sur chaque vol."}
          buttonURL = {'/fr/contact/'}
          buttonLabel = {'Commencer'}
          buttonIcon = {pageQuery['rightArrowIconWhite']}
          fullBG
        />


      <CruiseParnters>
        <div className="cruise-logo-container">
          <div className="logo-con">
            <GatsbyImage fluid={pageQuery['airCanada'].childImageSharp.fluid} imgStyle={{
              objectFit: "none",
              objectPosition: "50% 50%",
            }}/>
          </div>
          <div className="logo-con">
            <GatsbyImage fluid={pageQuery['latam'].childImageSharp.fluid} imgStyle={{
              objectFit: "none",
              objectPosition: "50% 50%",
            }}/>
          </div>
          <div className="logo-con">
            <GatsbyImage fluid={pageQuery['lufthansa'].childImageSharp.fluid} imgStyle={{
              objectFit: "none",
              objectPosition: "50% 50%",
            }}/>
          </div>
          <div className="logo-con">
            <GatsbyImage fluid={pageQuery['etihad'].childImageSharp.fluid} imgStyle={{
              objectFit: "none",
              objectPosition: "50% 50%",
            }}/>
          </div>
          <div className="logo-con">
            <GatsbyImage fluid={pageQuery['singapore'].childImageSharp.fluid} imgStyle={{
              objectFit: "none",
              objectPosition: "50% 50%",
            }}/>
          </div>
          <div className="logo-con">
            <GatsbyImage fluid={pageQuery['qantas'].childImageSharp.fluid} imgStyle={{
              objectFit: "none",
              objectPosition: "50% 50%",
            }}/>
          </div>
        </div>
        <Button destination={'/fr/partenaires/'} label={'Voir tous les partenaires'} icon={pageQuery['rightArrowIconWhite']} black whiteText pill/>
      </CruiseParnters>

        <div style={{backgroundColor: "#F6F6F6", paddingTop: "1px"}}>
          <PremUpgradeContainer 
            copy = {"Permettez aux passagers de faire une offre ou d'acheter un siège premium grâce à une expérience utilisateur adaptée à votre marque. Cette solution de surclassement, la plus largement adoptée dans le secteur, permet à plus de 80 compagnies aériennes dans le monde de générer des revenus auxiliaires à marge importante."}
            fr
          />
        </div>
        <PlatformRevenueContainer>
          <div className="background-con">
            <GatsbyImage fixed={pageQuery['revPlatformBackground'].childImageSharp.fixed} imgStyle={{objectFit: "cover", objectPosition: "50% 80%"}} />
          </div>
          <div className="content-con">
            <div className="icon-content-con">
              <IconContainer 
                icon = {pageQuery['revenueIncrease']}
                precopy={'Hausse de'}
                headline = {'225%'}
                copy = {'de revenus de surclassement dans la première année de l\'intégration de Plusgrade - Opérateur aérien européen'}
              />

              <IconContainer 
                icon = {pageQuery['partnerIncrease']}
                headline = {'5x'}
                copy = {'plus d\'engagement après la mise en œuvre du système Bid-to-Upgrade de Plusgrade'}
                precopy={' '}
              />

              <IconContainer 
                icon = {pageQuery['revenueIncrease']}
                headline = {'2x'}
                copy = {'plus de revenus de surclassement dans les 12 mois qui ont suivi l\'intégration de Plusgrade - Opérateur principal aérien d\'Amérique du Nord'}
                precopy={' '}
              />
            </div>
          </div>
          
        </PlatformRevenueContainer>

        <PlatformDetailContainer>
           <div className="inner-icon-container">
            <IconContainer whiteOnBlue
                  icon = {pageQuery['whiteLabel']}
                  headline = {'Solutions en marque blanche'}
                  copy = {'Une solution adaptée aux compagnies aériennes qui offrent une expérience client fluide et sans faille.'}
              />

              <IconContainer whiteOnBlue
                  icon = {pageQuery['zeroDilution']}
                  headline = {'Aucune dilution des revenus'}
                  copy = {'Règles et leviers commerciaux robustes conçus pour optimiser votre programme de surclassement.'}
              />

              <IconContainer whiteOnBlue
                  icon = {pageQuery['fastestTime']}
                  headline = {'Délai le plus rapide de mise sur le marché'}
                  copy = {'Commencez à générer des revenus supplémentaires en 12 semaines.'}
              />

              <IconContainer whiteOnBlue
                  icon = {pageQuery['fullInt']}
                  headline = {'Intégration complète de PSS'}
                  copy = {'Mise en œuvre rapide et facile, sans frais d\'installation ni de maintenance.'}
              />

              <IconContainer whiteOnBlue
                  icon = {pageQuery['merchantRecord']}
                  headline = {'Marchand attitré (MoR)'}
                  copy = {'Les revenus des surclassements sont payés immédiatement et vous reviennent directement.'}
              />

              <IconContainer whiteOnBlue
                  icon = {pageQuery['provenGrowth']}
                  headline = {'Croissance prouvée du chiffre d\'affaires d\'une année à l\'autre'}
                  copy = {'Maximisez vos profits grâce à des produits et des flux de revenus diversifiés.'}
              />
           </div>
        </PlatformDetailContainer>

        <SeatBlocker 
          title={'SeatBlocker'}
          copy={'Offrez aux passagers de la classe économique plus de confort et d\'espace en leur permettant de bloquer le siège, voire toute une rangée, à côté d\'eux. Avec SeatBlocker, les sièges sont « bloqués en douceur », ce qui permet de générer des revenus seulement sur les sièges non vendus et d\'adapter dynamiquement leur attribution en fonction des ventes et de l\'inventaire.'}
          buttonLabel={'Demander une démonstration'}
          buttonDestination={'/fr/contact/demandez-une-demonstration/'}
          />

        <PlatformPerksContainer>
          <IconContainer whiteOrange
                icon = {pageQuery['cloudUpload']}
                headline = {'Installation facile à intégrer'}
                copy = {'Mise en service en quatre semaines seulement. De plus, tous les rapports et la gestion du programme sont regroupés en un seul endroit.'}
            />

            <IconContainer whiteOrange
                icon = {pageQuery['creditCard']}
                headline = {'Intégration facile des paiements'}
                copy = {'Utilisez la même passerelle de paiement, les mêmes devises et les mêmes cartes de paiement que dans votre PSS existant.'}
            />

            <IconContainer whiteOrange
                icon = {pageQuery['offerDistribute']}
                headline = {'Distribution optimisée des offres'}
                copy = {'Exploitez les canaux de communication les plus performants pour atteindre le public le plus large.'}
            />
          
        </PlatformPerksContainer>

        <Testimonial 
          testimonials={
            [
              'Ce produit permet à Azores Airlines d\'offrir à ses passagers une toute nouvelle catégorie de sièges, en leur donnant la possibilité d\'avoir cet espace et ce confort supplémentaires qui font la différence dans leur expérience de voyage.-- Marco Silva, Ancillaries, auxiliaires, directeur du réseau et des revenus, Sata Azores Airlines',
              'Plusgrade est toujours à la recherche de ce qu\'il y a de mieux pour ses partenaires et est ouvert aux nouvelles initiatives et à l\'amélioration continue. Ils sont les éléments clés du développement des revenus auxiliaires pour Copa Airlines.--Vice-président commercial, responsable des revenus auxiliaires, Copa Airlines',
              '...si vous êtes assis en classe économique, le fait d\'être assis à côté de sièges vides est tout ce qu\'il y a de mieux.--ThePointsGuy.com, avis Etihad'
            ]
          }
          background={pageQuery['airTestimonials']}
          imageStyle = {{objectPosition: "center center"}}
        />


        <SpeedPass 
          buttonLabel={'Demander une Démonstration'}
          buttonDest={'/fr/contact/demandez-une-demonstration/'}
          fr
        />
        
        <SpeedPassPerksContainer>

          <div className="inner-speedpass-icon-con">
            <IconContainer whiteOrange
                  icon = {pageQuery['simpleUX']}
                  headline = {'Expérience utilisateur simple et optimisée'}
                  copy = {'Une conception « mobile-first » avec des estimations  « gamifiées » permettant de gagner du temps.'}
              />

              <IconContainer whiteOrange
                  icon = {pageQuery['generateAncillary']}
                  headline = {'Générez des revenus auxiliaires'}
                  copy = {'Améliorez la satisfaction de vos clients et monétisez les points de contact existants dans les aéroports avant que vos passagers ne prennent l\'avion.'}
              />

              <IconContainer whiteOrange
                  icon = {pageQuery['inventoryControl']}
                  headline = {'Contrôler l\'inventaire et exploiter les règles de tarification granulaires'}
                  copy = {'Ciblez les passagers avec des configurations basées sur l\'éligibilité et l\'inventaire.'}
              />
            </div>
        </SpeedPassPerksContainer>

        <LoyaltyCommerce fr/>

        <PointsLoyalty
          headline={'Plateforme commerciale de fidélisation'}
          copy={'La Loyalty Commerce Platform de Points, une société de Plusgrade, permet aux membres d\'échanger, de gagner et de convertir leurs points/miles sur des produits auxiliaires de partis tiers ou à travers des collaborations avec d\'autres marques. En connectant votre programme à un réseau de partenaires leaders du secteur, vous pouvez augmenter les revenus auxiliaires et étendre l\'utilité de vos points/miles.'}
          spaceBetween
          fr
        />


        <CallToAction
          title={'Générez des revenus auxiliaires sur des expériences client incroyables'}
          subtitle={'Contactez-nous aujourd\'hui pour en savoir plus'}
          > 
          <Button destination={'/fr/contact/demandez-une-demonstration/'} label={'Demander une démonstration'} icon={pageQuery['rightArrowIcon']} white blueText pill />
        </CallToAction>

      </Content>
    </Layout>
  );
}

export default IndustryCruisePage;

const IconCon = styled.div`

${ font.imports.sherika }
  flex-basis: 24%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media only screen and ${mq.maxMd} {
    flex-basis: 95%;
  }

  .icon-con {
    @media only screen and ${mq.maxMd} {
      text-align: center;
      margin: auto;
    }

  }

  .headline-con {
    @media only screen and ${mq.maxMd} {
      text-align: center;
      margin: auto;
    }
  }

  .headline {
    font-family: ${font.family.sherika};
    font-weight: 600 !important;
    color: ${brand.colors.pg_orange} !important;
    font-size: 48px !important;
    margin-bottom: 1px;
    @media only screen and ${mq.maxMd} {
      text-align: center;
    }
  }

  .headline-highlight {
    color: ${brand.colors.pg_primary_light};
    margin-top: 30px;
  }

  p{
    margin-top: 1px;
    font-family: ${font.family.sherika};
    font-weight: 700;
    font-size: 16px;
    color: ${brand.colors.pg_primary_light};
    @media only screen and ${mq.maxMd} {
      text-align: center;
    }
  }

  .pre-copy {
    margin-bottom: 0;
    height: 33px;
  }

  ${props => props.whiteOrange && css`
    flex-basis: 30%;
    align-items: flex-start;
    flex-wrap: wrap;

    .headline-con {
      text-align: left;


      .pre-copy {
        margin-bottom: 0;
        height: 33px;
      }

      h4 {
        color: ${brand.colors.pg_primary_dark} !important;
        font-family: ${font.family.sherika};
        font-size: 22px !important;
        font-weight: 700;
        white-space: pre-line;
        @media only screen and ${mq.maxMd} {
          text-align: center;
        }
      }
      p {
        color: ${brand.colors.pg_primary_dark};
        font-family: ${font.family.sherika};
        font-weight: 400;
        margin: 20px 0;
        min-height: 96px;
        @media only screen and ${mq.maxMd} {
          text-align: center;
        }
      }

      .headline{
        min-height: 60px;
        display: flex;
        align-items: center;
        @media only screen and ${mq.maxMd} {
          text-align: center;
          justify-content: center;
        }
      }
    }
  `}

  ${props => props.whiteOnBlue && css`
    flex-basis: 45%;
    align-items: flex-start;
    justify-content: center;
    text-align: left;

    .icon-con {
      text-align: left;
    }

    .headline-con {
      .headline {
        color: #fff !important;
        font-size: 26px !important;
      }
        .pre-copy {
          margin-bottom: 0;
          height: 33px;
        }
    }

    p {
      font-size: 16px !important;
      font-weight: 400 !important;
      margin-bottom: 0;
    }
  `}
`;

const IconContainer = ({icon, highlight, headline, copy, precopy, ...props }) => {
  const ic = ( icon ? <GatsbyImage fixed={icon.childImageSharp.fixed}/> : <></>);
  return(
    <IconCon precopy {...props}>
      <div className="icon-con">
        {ic}
      </div>
      <div className="headline-con">
        { precopy ? <p className="pre-copy" dangerouslySetInnerHTML={{__html: precopy}}></p> : <></> }
      <h4 className="headline-highlight">{highlight}</h4>
        <h4 className="headline">
          {headline}
        </h4>
        <p dangerouslySetInnerHTML={{__html: copy}}>
        </p>
      </div>

    </IconCon>
  )
}

export const query = graphql`
  fragment CruiseLogo on File {
    childImageSharp {
      fluid(maxWidth: 125, webpQuality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

